import { Component, Input,forwardRef } from '@angular/core';
import { ControlValueAccessor,FormControl,FormGroup,NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true
    }
  ]
})
export class FormInputComponent implements ControlValueAccessor {
  @Input() label: string='';
  @Input() parentForm!: FormGroup;
  @Input() fieldName!: string;
  @Input() type: string='text';
  @Input() minLength!:number;
  @Input() maxLength!:number;


  show:boolean=false;
  value: string='';
  disabled = false;
  changed:any = (value: any) => {};
  onTouched: any = () => {};


  get formField():FormControl{
    return this.parentForm?.get(this.fieldName) as FormControl;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  onChange(event:Event):void{
    const value=(event.target as HTMLInputElement).value

    this.changed(value);
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}