<form [formGroup]="formGroup">
  <lib-form-input
    label="Email Address"
    type="email"
    [parentForm]="formGroup"
    fieldName="email"
    formControlName="email"
    [required]="true"
  >
  </lib-form-input>
  <ng-content></ng-content>
  <div class="row" *ngIf="error">
    <div class="col-sm-12 center mb-1">
      <span class="error">{{ error }}</span>
    </div>
  </div>
  <div class="row">
    <lib-button
      type="submit"
      text="SEND CODE"
      variant="primary"
      size="md"
      [isFullWidth]="true"
      [isDisabled]="formGroup.invalid"
    ></lib-button>
  </div>
</form>
