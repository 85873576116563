<!-- <div class="file-selector" (click)="triggerFileInput()">
    <p *ngIf="!selectedFile">Click here to select a file</p>
    <p *ngIf="selectedFile">{{ selectedFile.name }}</p>
  </div>
  <input
    type="file"
    id="fileInput"
    accept=".xlsx,.xls"
    (change)="onFileSelected($event)"
    style="display: none"
  /> -->


  <div class="file-selector" (click)="triggerFileInput()">
    <p *ngIf="!selectedFiles?.length">Click here to select files</p>
  
    <!-- Single file selected -->
    <p *ngIf="!allowMultiple && selectedFiles?.length">
      {{ selectedFiles[0].name }}
      <span class="remove-icon" (click)="removeFile(0)">&#10006;</span>
    </p>
  
    <!-- Multiple files selected -->
    <div *ngIf="allowMultiple && selectedFiles?.length" class="file-list-box">
        <p *ngFor="let file of selectedFiles; let i = index" class="file-item">
          <span class="file-name">{{ file.name }}</span>
          <span class="remove-icon" (click)="removeFile(i)">&#10006;</span>
        </p>
      </div>
    <!-- <div *ngIf="allowMultiple && selectedFiles?.length" class="file-list-box">
      <p *ngFor="let file of selectedFiles; let i = index" class="file-item">
        {{ file.name }}
        <span class="remove-icon" (click)="removeFile(i)">&#10006;</span>
      </p>
    </div> -->
  </div>
  
  <input
    type="file"
    id="fileInput"
    [attr.multiple]="allowMultiple ? true : null"
    [attr.accept]="acceptedFiles"
    (change)="onFileSelected($event)"
    style="display: none"
  />