<!-- <span class="get_started">Get Started </span> -->

<button
  [type]="type"
  class="button"
  [id]="id"
  [ngClass]="{
    primary: variant === 'primary',
    secondary: variant === 'secondary',
    tertiary: variant === 'tertiary',
    alternate:variant==='alternate',
    sm: size === 'sm',
    md: size === 'md',
    lg: size === 'lg',
    pill: appearance === 'pill',
    rounded: appearance === 'rounded',
    isFullWidth: isFullWidth
  }"
  [disabled]="isDisabled"
>
  <ng-content select="[startIcon]"></ng-content>
  <span>{{ buttonText }}</span>
  <ng-content select="[endIcon]"></ng-content>
</button>
