<label class="custom-radio">
  <input
    type="radio"
    [value]="value"
    [disabled]="isDisabled"
    [checked]="checked"
    (change)="readonly ? null : onRadioChange()"
  />
  <p
    class="radio-button"
    [ngClass]="{
    primary: checked && variant === 'primary' && !isDisabled,
    secondary: checked && variant === 'secondary' && !isDisabled,
    checkDisabled:checked && isDisabled,
  }"
  >
    {{ label }}
  </p>
</label>
