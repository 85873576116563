import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss']
})
export class LibSignupFormComponent {
  @Input() formGroup: FormGroup;
  @Input() error!:string;
  @Input() showConfirm:boolean=false;

  constructor(private fb: FormBuilder) {
    this.formGroup = this.fb.group({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: undefined,
      password: '',
      confirmPassword:'',
      invitedBy: ''
    });
  }
}
