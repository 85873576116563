import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() navItems: any;
  @Input() selected = '';
  @Input() variant: 'primary' | 'secondary' = 'primary';
  @Output() NavigateTo = new EventEmitter<string>();

  navItemClick(item: string): void {
    this.selected = item;
    this.NavigateTo.emit(item);
  }

  getDynamicIconInputs(
    singleColor = false,
    selected: boolean,
    size?: number
  ): any {
    if (!singleColor) {
      return {
        size: size ?? 20,
        primaryColor:
          this.variant == 'primary'
            ? 'white'
            : selected
            ? '#1E5E56'
            : '#626E77',
        secondaryColor:
          this.variant == 'primary'
            ? 'white'
            : selected
            ? '#3FAB9D'
            : '#9FA6AD',
      };
    } else {
      return {
        size: size ?? 20,
        color:
          this.variant == 'primary'
            ? 'white'
            : selected
            ? '#1E5E56'
            : '#626E77',
      };
    }
  }
}
