import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Input() options: any[]; // An array of select options
  @Input() variant: 'primary' | 'secondary' = 'primary';
  @Output() optionSelected = new EventEmitter<{label:string;value:string}>();
  @Input() placeholder:string;
  @Input() selectedOption: any;
  isDropdownOpen: boolean = false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onOptionSelected() {
    this.optionSelected.emit(this.selectedOption);
  }

  selectOption(option: any) {
    this.selectedOption = option;
    this.isDropdownOpen = false;
    this.optionSelected.emit(option.value); // Emit the selected option value
  }
}
