<div class="custom-navbar">
  <div class="sidebar sidebar-nav desktop-view">
    <!-- logo -->
    <ng-content select="[header]"></ng-content>

    <!-- Menus -->
    <div>
      <ul class="nav navbar-nav side-nav">
        <li
          class="nav-item"
          [ngClass]="{ 
              'active-primary':variant=='primary'&&selected === item.name,
              'primary':variant=='primary'&&selected != item.name,
              'active-secondary':variant=='secondary'&&selected === item.name,
              'secondary':variant=='secondary'&&selected != item.name,
          }"
          *ngFor="let item of navItems"
        >
          <div (click)="navItemClick(item.name)" [attr.aria-disabled]="true" [id]="item.id">
            <div class="d-flex">
              <div class="nav-item-icon">
                <ng-container
                  *ngComponentOutlet="
                    item?.icon;
                    inputs: getDynamicIconInputs(
                      item?.singleColor,
                      selected == item.name,
                      item.size
                    )
                  "
                ></ng-container>
              </div>
              <span class="ms-2" style="margin-top: 0.15rem;"> {{ item?.title }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <!-- Mobile NAVBAR -->
  <div
    class="offcanvas offcanvas-start w-25 mobile-view"
    [ngClass]="{ 
      'mbg-primary':variant=='primary',
      'mbg-secondary':variant=='secondary',
    }"
    tabindex="-1"
    id="offcanvas"
    data-bs-keyboard="false"
    data-bs-backdrop="false"
  >
    <div class="offcanvas-header">
      <h6 class="offcanvas-title d-none d-sm-block" id="offcanvas">Menu</h6>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body px-2">
      <div class="nav">
        <ng-content select="[header]"></ng-content>
        <div
          *ngFor="let item of navItems"
          data-bs-dismiss="offcanvas"
          class="nav-item"
          [ngClass]="{ 
              'active-primary':variant=='primary'&&selected === item.name,
              'primary':variant=='primary'&&selected != item.name,
              'active-secondary':variant=='secondary'&&selected === item.name,
              'secondary':variant=='secondary'&&selected != item.name,
          }"
          (click)="navItemClick(item.name)"
          [attr.aria-disabled]="true"
        >
        <div class="d-flex">
          <div class="nav-item-icon">
            <ng-container
              *ngComponentOutlet="
                item?.icon;
                inputs: getDynamicIconInputs(
                  item?.singleColor,
                  selected == item.name,
                  item.size
                )
              "
            ></ng-container>
            <span class="ms-2"> {{ item?.title }}</span>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
