import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { AdminAuthGuard } from '@trialviu-v2/shared/guard/admin.auth.guard';
import { ResetPasswordGuard } from '../shared/guard/reset-password.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../modules/core/core.module').then(auth => auth.CoreModule)
  },
  {
    path: '',
    loadChildren: () => import('../modules/auth/auth.module').then(auth => auth.AuthModule)
  }, 
  {
    path: '',
    canActivate: [AdminAuthGuard,ResetPasswordGuard ],
    loadChildren: () => import('../modules/dashboard/dashboard.module').then(dashboard => dashboard.DashboardModule)
  },
  {
    path: 'patient',
    canActivate: [AdminAuthGuard,ResetPasswordGuard ],
    loadChildren: () => import('../modules/patient/patient.module').then(user => user.UserModule)
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule { }
