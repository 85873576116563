import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpContextToken } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

export const BYPASS_LOG = new HttpContextToken(() => false);
export class HttpError {
    static BadRequest = 400;
    static TooManyAttempts = 429;
    static Unauthorized = 401;
    static Forbidden = 403;
    static NotFound = 404;
    static TimeOut = 408;
    static Conflict = 409;
    static InternalServerError = 500;
    static ERR_CONNECTION_REFUSED = 0;
}

@Injectable({
    providedIn: 'root'
})


export class HttpErrorInterceptor implements HttpInterceptor {
    logFormat = 'background: maroon; color: white';
    
    constructor(
        private authService: AuthService,
        private router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.context.get(BYPASS_LOG) === true)
                return next.handle(request);
        return next.handle(request).pipe(
            tap(
                () => { },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        this.getHttpError(error);
                    }
                }
            ));
    }

    getHttpError(error: HttpErrorResponse): void {
        switch (error.status) {
            case HttpError.BadRequest:
                this.BadRequest(error);
                break;
            case HttpError.TooManyAttempts:
                this.TooManyAttempts(error);
                break;
            case HttpError.Unauthorized:
                this.Unauthorized(error);
                break;

            case HttpError.NotFound:
                this.NotFound(error);
                break;

            case HttpError.TimeOut:
                this.TimeOut(error);
                break;

            case HttpError.Forbidden:
                this.Forbidden(error);
                break;

            case HttpError.InternalServerError:
                this.InternalServerError(error);
                break;

            case HttpError.ERR_CONNECTION_REFUSED:
                this.defaultErrorMessage(error);
                break;
        }
    }

    BadRequest(error: HttpErrorResponse): any {
        // return `%c Bad Request 400, ${this.logFormat}`;
        const err = error.error;
        const details = err.error.details;

        Swal.fire({
            title: `Info`,
            html: details.message,
            icon: 'info',
        }).then((result: any) => {
            if (result.isConfirmed) {
                // this.router.navigateByUrl(`${message.url}`);
            }
        });
    }

    Unauthorized(error: HttpErrorResponse): any {
        const err = error.error;
        const details = err.error.details;

        let message = { error: `You don't have enough permission to perform this action.`, url: '/' };
        if (details && details.message === 'Invalid token.') {
            message = { error: 'Session has expired..., Please sign in to continue', url: '/' };
        } else if (details && details.message) {
            message = { error: details.message, url: '/' };
        }

        Swal.fire({
            title: `Unauthorized`,
            html: message.error,
            icon: 'info',
        }).then((result: any) => {
            if (result.isConfirmed) {
                if (details.message === 'Invalid token.') {
                    sessionStorage.clear();
                    this.authService.logout();
                    this.router.navigateByUrl(``);
                } else {
                    this.router.navigateByUrl(`${message.url}`);
                }
            }
        });
    }

    Forbidden(error: HttpErrorResponse): any {
        return `%c Bad Forbidden 403, ${this.logFormat, error}`;
    }

    TooManyAttempts(error: HttpErrorResponse): any {
        return `%c Too Many Attempts 429, ${this.logFormat, error}`;
    }

    NotFound(error: HttpErrorResponse): any {
        return `%c Bad Found 404, ${this.logFormat, error}`;
    }

    TimeOut(error: HttpErrorResponse): any {
        return `%c Bad TimeOut 408, ${this.logFormat, error}`;
    }
    Conflict(error: HttpErrorResponse): any {
        return `%c Bad Conflict 409, ${this.logFormat, error}`;
    }

    InternalServerError(error: HttpErrorResponse): any {
        Swal.fire('Error!', 'Something Went Wrong... Try again later or Contact System admin', 'error');
        return `%c Bad InternalServerError 500, ${this.logFormat, error}`;
    }

    defaultErrorMessage(error: HttpErrorResponse): any {
        Swal.fire({
            title: `ERROR CONNECTING TO SERVER`,
            html: 'Please check your internet connection and try again or contact System Admin... ',
            icon: 'info',
            width: '600px',
            allowOutsideClick: false,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                sessionStorage.clear();
            }
        });
        return `%c default Error Message, ${this.logFormat, error}`;
    }
}
