<ng-container *ngIf="columnType === 'text'; else other">
  <span class="normal-text">{{ columnData | truncate : 25  }}</span>
</ng-container>
<ng-template #other>
  <ng-container *ngIf="columnType === 'link'">
    <a [href]="columnData">{{ columnData }}</a>
  </ng-container>
  <ng-container *ngIf="columnType === 'button'">
    <lib-button
      type="button"
      [text]="columnData"
      width="100px"
      height="50px"
      borderRadius="20px"
      class="btn-submit"
    >
    </lib-button>
  </ng-container>
  <ng-container *ngIf="columnType === 'date'">
    <span class="normal-text">{{ columnData | date }}</span>
  </ng-container>
</ng-template>
