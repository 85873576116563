import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LibLoginFormComponent {
  @Input() formGroup: FormGroup;
  @Input() error!:string;

  constructor(private fb: FormBuilder) {
    this.formGroup = this.fb.group({
      email:'',
      password:''
    });
  }
}
