<table class="table table-hover share-record-desktop-table my-sharing-center">
  <thead>
    <tr class="thead">
      <th
        class="normal-text text-semi-black-gray"
        *ngFor="let column of columns"
      >
        {{ column.header }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of rows">
      <ng-container *ngFor="let column of columns">
        <td>
          <ng-container *ngIf="column.render; else defaultComponent">
            <ng-container
              *ngComponentOutlet="column.render(); inputs: { row: row }"
            ></ng-container>
          </ng-container>
          <ng-template #defaultComponent>
            <lib-dynamic-column
              [columnType]="column.type"
              [columnData]="row[column.key]"
            ></lib-dynamic-column>
          </ng-template>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
