<div class="form-group">
  <label class="form-label" [htmlFor]="fieldName">{{ label }}</label>
  <textarea
    [name]="fieldName"
    [id]="fieldName"
    class="form-control"
    [value]="value"
    (input)="onChange($event)"
    (blur)="onTouched()"
    [disabled]="disabled"
    [ngClass]="{
      'is-invalid': formField && formField.touched && formField.errors
    }"
  ></textarea>
  <div
    *ngIf="formField && formField.touched && formField.errors"
    class="invalid-feedback"
  >
    <div *ngIf="formField.hasError('required')">
      <small>{{ label }} is required</small>
    </div>
    <div *ngIf="formField.hasError('email')">
      <small>{{ label }} is invalid</small>
    </div>
    <div *ngIf="formField.hasError('pattern')">
      <small
        >{{ label }} must include an uppercase, a lowercase, a number and a
        symbol</small
      >
    </div>
  </div>
</div>
