import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingInterceptor } from '../shared/interceptors/http.loading.interceptor';
import { HttpErrorInterceptor } from '../shared/interceptors/http.error.interceptor';
import { LoadingModalComponent } from '@trialviu-v2/shared/components/loading-modal/loading-modal.component';
import { JwtInterceptor } from '@trialviu-v2/shared/interceptors/jwt.interceptor';
import { ComponentsLibraryModule } from 'components-library';

@NgModule({
  declarations: [
    AppComponent,
    LoadingModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    ComponentsLibraryModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
