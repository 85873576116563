<form [formGroup]="formGroup">
  <lib-form-input
    label="Email Address"
    type="email"
    [parentForm]="formGroup"
    fieldName="email"
    formControlName="email"
    [required]="true"
  >
  </lib-form-input>
  <lib-form-input
    label="Password"
    type="password"
    [parentForm]="formGroup"
    fieldName="password"
    formControlName="password"
    [required]="true"
  >
  </lib-form-input>
  <ng-content select="[verification]"></ng-content>

  <div class="row" *ngIf="error">
    <div class="col-sm-12 center mb-1">
      <span class="error">{{ error }}</span>
    </div>
  </div>
  <div class="row">
    <lib-button
      type="submit"
      text="Log in"
      appearance="pill"
      variant="primary"
      size="lg"
      [isFullWidth]="true"
    ></lib-button>
  </div>
  <ng-content select="[links]"></ng-content>
</form>
