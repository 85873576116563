import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(
    value: string,
    limit = 200,
    completeWords = false,
    ellipsis = '...'
  ): string {
    if (!value) {
      value = '...';
    }
    if (completeWords) {
      limit = value.length;
    }
    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}
