import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.css']
})
export class FormWrapperComponent {
  @Input() image: string ='';
  @Input() routerLink: string ='';
  @Input() formTitle: string='';
  @Input() subTitle: string='';
}