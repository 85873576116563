<div class="card form-card">
    <div class="card-body">
      <div class="d-flex justify-content-center align-items-center">
        <a [href]="routerLink">
          <img [src]="image" alt="image">
        </a>
      </div>
      <div class="form-card-header py-3 text-center">
        <span>{{ formTitle }}</span>
      </div>
      <span class="subTitle">{{subTitle}}</span>
      <br/>
      <ng-content></ng-content>
    </div>
</div>