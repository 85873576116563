import { Component, Input, Type } from '@angular/core';

interface TableColumn {
  key: string;
  header: string;
  type?: 'link' | 'text' | 'button' | 'date';
  render?: () => Type<any>;
}

@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() columns: TableColumn[];
  @Input() rows: any;
}
