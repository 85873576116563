import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit{

  //VARIABLES
  @Output() closeModal = new EventEmitter();
  @Output() uploadFunction = new EventEmitter();
  @Output() file = new EventEmitter();
  @Input()  allowMultiple = false; 
  @Input()  info = ''; 
  @Input() acceptedFiles = ".xlsx,.xls"; 
  @Input() validation?: (name:string) => Boolean;
  selectedFile: any[] | null;




  //CONSTRUCTOR
  //LIFECYCLE
  ngOnInit(): void {
    if(this.allowMultiple){
    this.selectedFile = [];
    }else{
  this.selectedFile = null;
    }
  }
  
  //FUNCTIONS
  onCloseModalCLick(): void {
    this.closeModal.emit();
  }

  onUploadNextGenFileClick(): void{
    this.uploadFunction.emit();
  }

  getFileDetails(event:any): void{
    this.selectedFile = event;
    this.file.emit(event)
  }


}
