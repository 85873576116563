<div class="custom-dropdown">
  <div
    class="dropdown-header"
    [ngClass]="{
      primary: variant === 'primary',
      secondary: variant === 'secondary',
    }"
    (click)="toggleDropdown()"
  >
    {{
      selectedOption
        ? selectedOption.label
        : placeholder
          ? placeholder
          : "Select an option"
    }}
    <ng-content *ngIf="options?.length > 1" select="[icon]"></ng-content>
  </div>
  <ul class="options-list" *ngIf="isDropdownOpen && options?.length>1">
    <li
      *ngFor="let option of options"
      [ngClass]="{
        selected: selectedOption && selectedOption.label == option.label
      }"
      (click)="selectOption(option)"
    >
      {{ option.label }}
    </li>
  </ul>
</div>
