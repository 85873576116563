import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class LibButtonComponent {
  public buttonText = '';
  @Input() variant: 'primary' | 'secondary' | 'tertiary'|'alternate' = 'primary';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() appearance: 'pill' | 'default' | 'rounded' = 'pill';
  @Input() isFullWidth=false;
  @Input() id = 'button';
  
  @Input()
  set text(name: string) {
    // this.buttonText = name.toUpperCase();
    this.buttonText = name;
  }

  get name(): string {
    return this.buttonText;
  }

  @Input() type = 'button';
  @Output() btnClick = new EventEmitter();
  @Input() isDisabled = false;
  constructor() {}

  onClick() {
    this.btnClick.emit();
  }
}
