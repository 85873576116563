<form [formGroup]="formGroup">
  <div class="form-group">
    <div class="row">
      <div class="col-12 col-lg-6">
        <lib-form-input
          label="First Name"
          type="text"
          [minLength]="2"
          [maxLength]="40"
          [parentForm]="formGroup"
          fieldName="firstName"
          formControlName="firstName"
          [required]="true"
        >
        </lib-form-input>
      </div>

      <div class="col-12 col-lg-6">
        <lib-form-input
          label="Last Name"
          type="text"
          [minLength]="2"
          [maxLength]="40"
          [parentForm]="formGroup"
          fieldName="lastName"
          formControlName="lastName"
          [required]="true"
        >
        </lib-form-input>
      </div>
    </div>
  </div>

  <lib-form-input
    label="Email address"
    type="email"
    [parentForm]="formGroup"
    fieldName="email"
    formControlName="email"
    [required]="true"
  >
  </lib-form-input>
  <ng-content select="[phone]"></ng-content>
  <lib-form-input
    label="Password"
    type="password"
    [parentForm]="formGroup"
    fieldName="password"
    formControlName="password"
    [required]="true"
    [minLength]="8"
    [maxLength]="50"
  >
  </lib-form-input>
  <lib-form-input
   *ngIf="showConfirm"
    label="Confirm Password"
    type="password"
    [parentForm]="formGroup"
    fieldName="confirmPassword"
    formControlName="confirmPassword"
    [required]="true"
    [minLength]="8"
    [maxLength]="50"
  >
  </lib-form-input>
  <ng-content *ngIf="showConfirm" select="[confirmError]"></ng-content>
  <div class="row" *ngIf="error">
    <div class="col-sm-12 center mb-1">
      <span class="error">{{ error }}</span>
    </div>
  </div>
  <ng-content select="[privacy]"></ng-content>
  <div class="row">
    <lib-button
      type="submit"
      text="Sign up"
      variant="primary"
      [isFullWidth]="true"
      size="lg"
      [isDisabled]="formGroup.invalid"
    >
    </lib-button>
  </div>
</form>
