import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AdminAuthService } from '@trialviu-v2/shared/services/admin-auth.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuard implements CanActivate {
  constructor(
    private adminAuthService: AdminAuthService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (this.adminAuthService.hasResetPassword()) {
      return true;
    } else {
      this.router.navigate(['admin/reset-password']);
      return false;
    }
  }
}
