import { Component } from '@angular/core';

@Component({
  selector: 'lib-components-library',
  template: `
    <p>
      components-library works!
    </p>
  `,
  styles: [
  ]
})
export class ComponentsLibraryComponent {

}
