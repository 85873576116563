import { Component, Input,  } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'lib-dynamic-column',
  templateUrl: './dynamic-column.component.html',
  styleUrls: ['./dynamic-column.component.css'],
})
export class DynamicColumnComponent {
  @Input() columnType: 'button' | 'link' | 'text' | 'date';
  @Input() columnData;


  toDate(data:any){
    return new Date(data as string).toDateString();
  }
}
