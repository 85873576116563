import { NgModule } from '@angular/core';
import { ComponentsLibraryComponent } from './components-library.component';
import { FormWrapperComponent } from './form-wrapper/form-wrapper.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LibLoginFormComponent } from './login-form/login-form.component';
import { LibSignupFormComponent } from './signup-form/signup-form.component';
import { LibButtonComponent } from './button/button.component';
import { LibForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { SelectComponent } from './select/select.component';
import { TableComponent } from './table/table.component';
import { DynamicColumnComponent } from './dynamic-column/dynamic-column.component';
import { FullNameComponent } from './full-name/full-name.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileSelectorComponent } from './file-selector/file-selector.component';

@NgModule({
  declarations: [
    ComponentsLibraryComponent,
    FormWrapperComponent,
    FormInputComponent,
    LibLoginFormComponent,
    LibSignupFormComponent,
    LibButtonComponent,
    LibForgotPasswordFormComponent,
    RadioButtonComponent,
    TextAreaComponent,
    SelectComponent,
    TableComponent,
    DynamicColumnComponent,
    FullNameComponent,
    TruncatePipe,
    SidebarComponent,
    FileUploadComponent,
    FileSelectorComponent,
  ],
  imports: [ReactiveFormsModule, CommonModule, FormsModule],
  exports: [
    ComponentsLibraryComponent,
    FormWrapperComponent,
    FormInputComponent,
    RadioButtonComponent,
    LibSignupFormComponent,
    LibLoginFormComponent,
    LibForgotPasswordFormComponent,
    TextAreaComponent,
    SelectComponent,
    LibButtonComponent,
    TableComponent,
    FullNameComponent,
    SidebarComponent,
    FileUploadComponent,
    FileSelectorComponent,
  ],
})
export class ComponentsLibraryModule {}
