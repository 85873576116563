import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class LibForgotPasswordFormComponent {
  @Input() formGroup: FormGroup;
  @Input() error!:string;

  constructor(private fb: FormBuilder) {
    this.formGroup = this.fb.group({});
  }
}
