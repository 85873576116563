import { Component, Input} from '@angular/core';

@Component({
  selector: 'lib-full-name',
  templateUrl: './full-name.component.html',
  styleUrls: ['./full-name.component.css']
})
export class FullNameComponent {
  @Input() row:any;
}
