import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  @Input() value: any;
  @Input() checked: boolean;
  @Input() label: string;
  @Input() readonly:boolean;
  @Input() isDisabled:boolean;
  @Input() variant:'primary'|'secondary';
  @Output() radioChange = new EventEmitter<string>();

  onRadioChange() {
    if (!this.checked) {
      this.checked = true;
      this.radioChange.emit(this.value);
    }else{
      this.checked=false;
      this.radioChange.emit(this.value)
    }
  }
}
